import React from 'react';
import './Select.css';

class Select extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange = (e) => {
        const { value } = e.target;
        this.props.onSelectChange(value);
    };

    returnOptionTags() {
        const optionElements = this.props.options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
        ));
        return optionElements;
    }

    render() {
        const optionElements = this.returnOptionTags();
        return (
            <>
                <select id="select" onChange={this.handleChange}>
                    {optionElements}
                </select>
            </>
        );
    }
}

export default Select;
