import React, { useState, useContext } from 'react';
import Input from './Input';
import Select from './Select';
import { LanguageContext, translations } from './LanguageContext';
import ReactInputMask from 'react-input-mask';
import { multispace, inexgroup } from './constants';
function Form() {
    const selectedLanguage = useContext(LanguageContext);
    const placeholders = translations[selectedLanguage].placeholders;
    const [formData, setFormData] = useState({
        name: '',
        post: '',
        email: '',
        phone: '',
        signature: 'inexgroup'
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };


    const saveHTML = () => {
        const template = document.querySelector(`.${formData.signature}`);
        template.querySelector('#email').textContent = formData.email;
        template.querySelector('#name').textContent = formData.name;
        template.querySelector('#post').textContent = formData.post;
        template.querySelector('#phone').textContent = formData.phone;
        const blob = new Blob([template.innerHTML], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'form_data.html';
        a.click();
        URL.revokeObjectURL(url);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        saveHTML();
    };

    const handleSelectChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            signature: value
        }));
    };



    return (
        <>
            <form className="form" onSubmit={handleSubmit}>
                <Select className="select" id="signature"
                    options={['inexgroup', 'multispace']}
                    onSelectChange={handleSelectChange}
                />
                <input
                    className='input'
                    id="name"
                    placeholder={placeholders.name}
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    className='input'
                    id="post"
                    placeholder={placeholders.post}
                    value={formData.post}
                    onChange={handleChange}
                    required
                />
                <input
                    className='input'
                    id="email"
                    placeholder={placeholders.email}
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <ReactInputMask
                    mask='+999 99999999'
                    maskChar=''
                    className='input'
                    id="phone"
                    placeholder={placeholders.phone}
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    guide={false}
                />
                <button type="submit" className="button">
                    {placeholders.button}
                </button>
            </form>
            <div className='multispace' style={{ display: 'none' }}>{multispace}</div>
            <div className='inexgroup' style={{ display: 'none' }}>{inexgroup}</div>
        </>
    );
}

export default Form;
