import React, { useState } from 'react';
import Input from './Input';
import Select from './Select';
import { multispace, inexgroup } from './constants';
import Form from './Form';
import { LanguageContext } from './LanguageContext';
import Readme from './Readme';
const FileSaver = require("file-saver");

function App() {
  const [selectedLanguage, setLanguage] = useState('english');
  return (
    <>
      <header className='header'>
        <img src="https://static.tildacdn.com/tild3934-3537-4430-a662-613435666462/Group_15.svg"></img>
        <div className='language-select__div'>
          <div className={selectedLanguage === 'english' ? 'language-select__item_active' : 'language-select__item'}
            onClick={() => setLanguage('english')}
          >En</div>
          <div className={selectedLanguage === 'russian' ? 'language-select__item_active' : 'language-select__item'}
            onClick={() => setLanguage('russian')}
          >Ru</div>
        </div>
      </header>
      <LanguageContext.Provider value={selectedLanguage}>
        <Readme os='windows' />
        <Readme os='macos' />
        <Form />
      </LanguageContext.Provider>
    </>
  )
}

export default App;

