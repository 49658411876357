import { useContext, useState } from "react";
import { LanguageContext, translations } from "./LanguageContext";
import './Readme.css';
function Readme({ os }) {
    const [isOpened, setOpened] = useState(false); // установите значение по умолчанию для isOpened
    const selectedLanguage = useContext(LanguageContext);
    const text = translations[selectedLanguage]?.readme?.[os]; // проверяем наличие readme[selectedLanguage] и readme[selectedLanguage][os]

    return (
        <>
            <div className="readme__button__block">
                <div
                    className={`readme__button readme__button${isOpened ? '_active' : ''}`}
                    onClick={() => setOpened(!isOpened)}>
                    <p>{text?.title}</p>
                </div>
                <div className={`readme__div readme__div${isOpened ? '_active' : ''}`}>
                    <div className={`readme__text readme__text${isOpened ? '_active' : ''}`}>
                        {
                            os === 'windows' ?
                                <>
                                    <p>{text?.list_1}</p>
                                    <p>{text?.list_2}</p>
                                    <p>{text?.list_3}</p>
                                    <p>{text?.list_4}</p>
                                    <p
                                        style={{ padding: '15px', boxShadow: '0px 0px 0px 1px #fff inset', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{text?.path}</p>
                                    <p>{text?.p_1}</p>
                                    <p style={{ padding: '15px', boxShadow: '0px 0px 0px 1px #fff inset', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{text?.tip_1}</p>
                                    <p style={{ padding: '15px', boxShadow: '0px 0px 0px 1px #fff inset', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{text?.tip_2}</p>
                                    <p style={{ textAlign: 'center' }}>{text?.end}</p>
                                </>
                                :
                                <>
                                    <p>{text?.list_1}</p>
                                    <p>{text?.list_2}</p>
                                    <p>{text?.list_3}</p>
                                    <p>{text?.list_4}</p>
                                    <p>{text?.list_5}</p>
                                    <p style={{ textAlign: 'center' }}>{text?.end}</p>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Readme;
