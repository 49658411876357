import { createContext, useContext } from "react"

export const translations = {
    english: {
        placeholders: {
            name: 'Full Name',
            post: 'Position',
            email: 'E-mail',
            phone: 'Phone Number',
            button: 'Download'
        },
        readme: {
            windows: {
                title: 'How to use on Windows',
                list_1: '1. Choose your company from the list.',
                list_2: `2. Fill in all the fields. The fields 'E-mail' and 'Phone number' suggest that you type in your business contacts.`,
                list_3: `3. Click the button 'Download'. You will get the file named '[companyName].htm'. Open it and check that all the data is correct.`,
                list_4: `4. If the data is correct, copy the next path:`,
                path: `C:/Users/[yourName.Surname]/AppData/Roaming/Microsoft/Signatures`,
                p_1: `Press the key combination Win+R on your keyboard, paste the copied path to the field which is displayed now and press the Enter button. The 'Signatures' directory will be opened. Copy the file from the previous paragraph to this directory.`,
                tip_1: `The directory 'Signatures' can be missing on your system. It happens if you have never before logged in to the Outlook mail app. In that case open the Outlook mail app, fill in your credentials and connect to the mail. Press the button 'Send/Receive All Folders' in the upper ribbon under the tab 'Send / Receive'. After that the 'Signatures' directory will be presented on your system and you can return to the paragraph 4.`,
                tip_2: `To show hidden folders use this manual:`,
                end: `Now you can pick your new signature in the Outlook messages.`

            },
            macos: {
                title: 'How to use on MacOS',
                list_1: `1. Choose your company from the list.`,
                list_2: `2. Fill in all the fields. The fields 'E-mail' and 'Phone number' suggest that you type in your business contacts.`,
                list_3: `3. Click the button 'Download'. You will get the file named '[companyName].htm'. Open it and check that all the data is correct. If any prompt about security issues with the file is displayed, ignore it and download the file anyway.`,
                list_4: `4. If the data is correct, open the Outlook mail app on your PC. Point your mouse upon the screen, choose 'Outlook' -> 'Preferences' -> 'Signatures'. Click the '+' button, type in a name for the new signature. After that it will be displayed in the list of all signatures. Choose the new signature and click the 'Change' button. A text editor will be opened.`,
                list_5: `5. Return to the file from the paragraph 3. Copy its content including the image. Paste in the copied content into the text editor from the paragraph. Save the changes and close the editor. Make sure that the new signature is ready.`,
                end: `Now you can pick your new signature in the Outlook messages.`
            }
        }
    },

    russian: {
        placeholders: {
            name: 'ФИО',
            post: 'Должность',
            email: 'E-mail',
            phone: 'Номер телефона',
            button: 'Скачать'
        },
        readme: {
            windows: {
                title: 'Инструкция Windows',
                list_1: '1. Выберите вашу компанию.',
                list_2: `2. Заполните все поля. В полях "E-mail" и "Номер телефона" укажите ваши рабочие контакты.`,
                list_3: `3. Нажмите кнопку "Скачать". У вас скачается файл "[имя_компании].htm". Откройте его и проверьте, что подпись заполнена верно.`,
                list_4: `4. Если всё верно, скопируйте следующий путь:`,
                path: `C:/Users/[вашеИмя.вашаФамилия]/AppData/Roaming/Microsoft/Signatures`,
                p_1: `Нажмите Win+R, вставьте туда этот путь и нажмите Enter. Откроется папка. Скопируйте в эту папку файл из п.3.`,
                tip_1: `Папка "Signatures" может отсутствовать, если до этого вы никогда не заходили в почту Outlook на компьютере. В этом случае, запустите Outlook, введите рабочую почту и пароль от неё, подключитесь к почте и загрузите новые сообщения через кнопку "Отправить и получить почту - все папки". После этого у вас появится искомая папка по пути, указанному в п. 4.`,
                tip_2: `Чтобы отобразить скрытые папки, воспользуйтесь инструкцией:`,
                end: `Теперь вы можете использовать новую подпись в Outlook.`
            },
            macos: {
                title: 'Инструкция MacOS',
                list_1: `1. Выберите вашу компанию.`,
                list_2: `2. Заполните все поля. В полях "E-mail" и "Номер телефона" укажите ваши рабочие контакты.`,
                list_3: `3. Нажмите кнопку "Скачать". У вас скачается файл "[имя_компании].htm". Откройте его и проверьте, что подпись заполнена верно. Если при открытии файла появится предупреждение, что файл неизвестен, согласитесь с его открытием. Не закрывайте файл.`,
                list_4: `4. Если в подписи всё верно, откройте приложение Outlook на вашем компьютере. Наведите курсор мыши вверх экрана, нажмите "Outlook" -> "Параметры" -> "Подписи". Нажмите "+", введите имя для новой подписи. После того, как она появится в списке подписей, нажмите "Изменить". Откроется текстовый редактор.`,
                list_5: `5. Вернитесь в файл из пункта 3 и скопируйте целиком подпись: изображение и текст. Вставьте скопированное изображение и текст в текстовый редактор из пункта. Сохраните изменения, закройте редактор. Убедитесь, что при выборе новой подписи отображается скопированное содержимое.`,
                end: `Теперь вы можете использовать новую подпись в Outlook.`
            }
        }
    }
}

export const LanguageContext = createContext(translations);